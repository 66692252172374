@import '../../styles/mixins';

.examples-counter-page {
  color: #555;
  h1 {
    margin-top: 0px;
    font-size: 28px;
  }
  span {
    padding: 0 10px;
    display: inline-block;
    min-width: 30px;
    text-align: center;
  }
  button.btn-reset {
    margin-left: 15px;
  }
}
