@import '../../styles/mixins';

.examples-side-panel {
  position: fixed;
  box-sizing: border-box;
  overflow: auto;
  top: 0;
  left: 0;
  margin: 0;
  padding: 40px;
  width: 260px;
  height: 100%;
  background-color: #f7f7f7;
  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  li {
    padding: 8px;
  }

  a {
    color: #2db7f5;
    text-decoration: none;
    &:hover {
      color: #f90;
    }
  }

  .memo {
    &:before {
      content: ' ';
      display: block;
      height: 2px;
      width: 60px;
      margin-bottom: 10px;
      background-color: #ddd;
    }
    font-size: 13px;
    margin-top: 40px;
    line-height: 150%;
    color: #aaa;
  }
}
