@import '../../styles/mixins';

.examples-reddit-list-page {
  color: #555;
  h1 {
    margin-top: 0px;
    font-size: 28px;
  }
  a {
    color: #2db7f5;
    text-decoration: none;
    &:hover {
      color: #f90;
    }
  }
  .fetch-list-error {
    display: block;
    margin-top: 20px;
    color: red;
  }
  .no-items-tip {
    margin-top: 15px;
  }
}
