@import './mixins';

// Here you put all global css rules.

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.fd-spinner {
  &.page-load-spinner {
    margin-top: calc(50vh - 60px);
  }
}

.fd-shell {
  height: auto !important;
}

.fd-app {
  // .fd-app__navigation.fd-app__navigation--vertical {
  //   height: calc(100vh - 48px);
  // }
  // .fd-app__navigation.fd-app__navigation--vertical + main.fd-app__main {
  //   width: calc(100% - 250px);
  //   height: calc(100vh - 48px);
  // }
  .fd-app__navigation--vertical {
    overflow-y: auto;
    -webkit-box-flex: auto;
    -ms-flex: 0;
    flex: auto;
  }
}

.list-view {
  .fd-panel__body {
    padding: 0;
  }

  .fd-panel__actions {
    > button,
    .fd-button-group {
      margin-left: 8px;
    }
  }
}

.detail-view,
.edit-view {
  .form-text {
    color: var(--fd-color-text-1);
  }
  .amount {
    color: #666666;
    font-family: '72', sans-serif;
    font-weight: 800;
    font-size: 2.2rem !important;
  }
  .unit {
    color: #787777;
    font-family: '72', sans-serif;
    font-weight: bold;
    font-size: 1rem !important;
  }
  .fd-tree__col--control {
    width: 50px;
  }
  .fd-tree__row--header {
    background-color: #fff;
  }
}

.fd-page {
  .page-title {
    margin: 12px 0;
  }
}
