@import '../../styles/mixins';

.home-welcome-page {
  text-align: center;
  .app {
    text-align: center;
  }

  .rekit-logo {
    animation: app-logo-spin infinite 10s linear;
    height: 80px;
  }

  .app-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
    overflow: hidden;
    position: relative;
  }

  .app-title {
    font-size: 1.5em;
  }

  .app-intro {
    font-size: large;
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul {
    margin-top: 20px;
  }
  li {
    margin-top: 10px;
  }

  a {
    color: #0288d1;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  p.memo {
    width: 500px;
    color: #999;
    font-size: 12px;
    line-height: 150%;
    margin: auto;
  }

  @keyframes app-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
