@import '../../styles/mixins';

.examples-welcome-page {
  line-height: 160%;
  position: relative;
  color: #555;

  a {
    color: #2db7f5;
    text-decoration: none;
    &:hover {
      color: #f90;
    }
  }

  .app-logo {
    position: absolute;
    top: -14px;
    left: 0px;
    width: 50px;
  }

  h1 {
    padding-left: 60px;
    margin-bottom: 40px;
    font-size: 28px;
  }

  h3 {
    margin-top: 20px;
  }

  code {
    font-size: 14px;
  }
}
